import React, {
  PropsWithChildren,
  useContext,
  useState,
  createContext,
  useEffect,
} from "react";

export interface IAccessibility {
  fontSize: number;
  contrast: "normal" | "high";
  color: "normal" | "inverted";
}

type AccessibilityContextType = {
  accessibility: IAccessibility;
  setAccessibility: React.Dispatch<React.SetStateAction<IAccessibility>>;
  increaseFonts: () => void;
  decreaseFonts: () => void;
  toggleContrast: () => void;
};

export const AccessibilityContext = createContext(
  {} as AccessibilityContextType
);

const ACCESSIBILITY_SETTINGS_STORAGE = "accessibility-settings";

export const AccessibilityContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const MIN_FONT_SIZE = 12;
  const MAX_FONT_SIZE = 24;
  const MIN_PADDING_SIZE = 24;
  const MAX_PADDING_SIZE = 50;

  // Fator que determina como o espaçamento escala em relação à fonte
  const SPACING_SCALE_FACTOR = 3.5;

  const defaultAccessibility: IAccessibility = {
    fontSize: 16,
    contrast: "normal",
    color: "normal",
  };

  const [accessibility, setAccessibility] = useState<IAccessibility>(() => {
    const storedData = sessionStorage.getItem(ACCESSIBILITY_SETTINGS_STORAGE);

    return storedData ? JSON.parse(storedData) : defaultAccessibility;
  });

  useEffect(() => {
    sessionStorage.setItem(
      ACCESSIBILITY_SETTINGS_STORAGE,
      JSON.stringify(accessibility)
    );
  }, [accessibility]);

  useEffect(() => {
    updateFontSizes(accessibility.fontSize - defaultAccessibility.fontSize);
    updateSpacingVariables(
      accessibility.fontSize - defaultAccessibility.fontSize
    );
    applyContrastTheme(accessibility.contrast);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fontVariables = [
    "--font-title-h1",
    "--font-title-h2",
    "--font-title-h5",
    "--font-subtitle-1",
    "--font-subtitle-2",
    "--font-subtitle-3",
    "--font-body-1",
    "--font-body-2",
    "--font-button",
    "--font-menu-item",
    "--font-empty-text",
    "--font-search-input",
    "--font-search-input-header",
  ];

  const spacingVariables = ["--padding-top-card-service"];

  const colorThemes = {
    normal: {
      "--color-background-primary": "var(--color-background-primary)",
      "--color-background-secondary": "var(--color-background-secondary)",
      "--color-background-dark": "#0049a9",
      "--color-background-subfooter": "#0a2f66",
      "--color-secondary-10": "var(--color-secondary-10)",
      "--color-grey-90": "#28272c",
      "--color-grey-80": "#727687",
      "--color-surface": "#ffffff",
      "--color-title-h1": "#28272c",
      "--color-title-h2": "#28272c",
      "--color-body-1": "#727687",
      "--color-body-2": "#999eb0",
      "--color-menu-item": "#727687",
      "--xvia-home-active-contrast": "",

      "--color-link": "var(--color-link)",
      "--color-link-secondary": "#28272C",
      "--color-link-footer": "var(--color-link-footer)",
      "--color-link-footer-hr": "#fdfdfd",

      "--color-link-contrast": "#727687",

      "--color-button-primary-hover": "#0a2f66",
      "--color-button-primary": "#0049a9",
      "--color-button-text-contrast": "#ffffff",

      // === Input ===
      "--color-input-background": "#fdfdfd",
      "--color-input-border": "2px solid #d6d7e0",
      "--color-input-text": "#000",
      "--color-input-text-placeholder": "#000",
      "--color-input-outlined-border": "1.5px solid #D6D7E0",
      "--color-input-outlined-background": "transparent",
      // === Input ===

      "--xvia-header-icon-search-contrast": "#28272C",

      "--xvia-color-title-contrast": "#fdfdfd",

      // === text contrast ===
      "--color-title-contrast": "#28272c",
      "--color-text-contrast": "var(--color-text-contrast)",
      "--color-text-contrast-90": "#28272c",
      "--color-subtitle-1": "#727687",
      // === text contrast ===

      "--color-link-header": "#28272C",

      "--color-link-contrast-hover": "#0a2f66",
      "--color-background-link-contrast-hover": "transparent",

      "--xvia-header-spacing-active-contrast": "#fdfdfd",
      "--color-background-header": "#fff",
      "--color-link-contrast-active": "#0049A9",

      "--color-button-inactive-text": "#000000",
      "--color-button-inactive": "#C0C1CE",

      "--color-link-top-bar": "#FDFDFD",
      "--color-button-background-top-bar": "#0A2F66",

      "--color-background-button-primary-outlined": "#ffffff",
      "--color-background-button-primary-outlined-border": "1px solid #0049a9",
      "--color-background-button-primary-outlined-hover": "#0049a91a",
      "--color-button-text-primary-outlined": "#0049a9",

      // === text card ===
      "--color-background-card-contrast": "#d6d7e0",
      "--color-background-service-card-contrast": "#fff",
      "--color-title-card-small-mark-contrast": "#727687",
      "--color-title-card-contrast": "#28272c",
      "--color-text-card-contrast": "#28272c",
      "--color-text-service-card-contrast": "#28272c",
      "--color-link-card-contrast": "#0049a9",
      "--color-background-service-card-contrast-border":
        "2px solid transparent",
      // === text card ===
      "--color-background-button-primary-contrast": "#0049a9",
      "--color-background-switch-checked": "#0049a9",
      "--color-button-active": "#0049a91a",

      // === header detail ===
      "--color-gradient-primary": "var(--color-grey-40)",

      // === breadcrumb ===
      "--color-breadcrumb": "var(--color-grey-80)",
      "--color-breadcrumb-hover": "var(--color-grey-90)",

      // === select input ===
      "--color-select-input-label": "var(--color-grey-90)",
      "--color-select-input-options": "var(--color-grey-90)",
      "--color-select-input-options-hover": "var(--color-primary)",
      "--color-background-select-input-options-hover": "var(--color-grey-20)",

      // === card accordion secretariats and bodies ===
      "--color-card-accordion-subtitle": "var(--color-grey-80)",
      "--color-card-accordion-item": "var(--color-grey-80)",
      "--color-card-accordion-arrow": "var(--color-primary)",
      // === governor page ===
      "--color-card-governor": "var(--color-grey-10)",
      "--color-card-governor-text": "var(--color-grey-80)",
      "--color-governor-text": "var(--color-grey-80)",
      // === tab component ===
      "--color-background-tab-trigger": "#f8f8f8",
      "--color-text-tab-trigger": "#999eb0",
      "--color-text-tab-trigger-border-bottom": "#8ec63f",

      // === dot component ===
      "--color-background-dot": "#fdfdfd",
      "--color-background-dot-active": "#0049a9",

      "--color-button-service-card-contrast": "#fff",
      "--color-link-service-card-contrast": "#fff",

      "--color-button-background-arrow": "transparent",
      "--color-button-border-arrow": "2px solid #0049a9",
      "--color-icon-arrow": "#0049a9",

      // === enum pagination ===
      "--color-icon-arrow-hover": "#fff",
      "--color-button-border-arrow-hover": "#0049a9",
      "--color-button-background-arrow-hover": "#0049a9",
      "--color-number-pagination": "#999EB0",
      "--color-number-pagination-hover": "#0049A9",
      "--color-background-number-pagination-hover": "transparent",
      "--color-number-pagination-active": "#0049A9",

      "--color-text-side-filter-contrast": "#28272c",
      "--color-text-side-filter-active-contrast": "#28272c",
      "--color-background-base-video": "#fdfdfd",

      "--color-button-background-fill-arrow": "#fff",
      // === header Input search ===
      "--color-header-input-background": "#fff",
      "--color-header-input-border": "2px solid transparent",
      "--color-header-input-text": "#000",
      "--color-header-input-icon": "#000",

      // === card component ===
      "--color-background-card": "#ffffff",
      "--color-border-card": "1px solid transparent",

      // === calendar component ===
      "--color-arrow-picker": "#747474",
      "--color-arrow-picker-disabled": "#bcbcbc",
    },
    high: {
      "--color-background-primary": "#000000",
      "--color-background-secondary": "#000000",
      "--color-background-dark": "#000000",
      "--color-background-subfooter": "#000000",
      "--color-surface": "#333333",
      "--color-title-h1": "#ffffff",
      "--color-title-h2": "#ffffff",
      "--color-body-1": "#ffffff",
      "--color-body-2": "#e0e0e0",
      "--color-menu-item": "#ffffff",
      "--xvia-home-active-contrast": "none !important",

      "--color-link": "#40c090",
      "--color-link-footer": "#00ffff",
      "--color-link-footer-hr": "#8ec63f",
      "--color-grey-90": "#40c090",
      "--color-grey-80": "#40c090",
      "--color-secondary-10": "#600040",

      "--color-button-primary-hover": "#400000",
      "--color-button-primary": "transparent",
      "--color-button-text-contrast": "#00ffff",

      // === Input ===
      "--color-input-background": "#000",
      "--color-input-border": "2px solid #fff",
      "--color-input-text": "#fff",
      "--color-input-text-placeholder": "#fff",
      "--color-input-outlined-background": "#000",
      // === Input ===
      "--color-input-outlined-border": "1.5px solid #fff",
      // === Input ===

      // === header Input search ===
      "--color-header-input-background": "#000000",
      "--color-header-input-border": "2px solid #fff",
      "--color-header-input-text": "#fff",
      "--color-header-input-icon": "#40c090",
      // === header Input search ===

      "--color-title-contrast": "#40c090",
      "--color-text-contrast": "#fdfdfd",

      "--xvia-color-title-contrast": "#40c090",

      "--color-link-contrast": "#0080ff",

      "--color-link-secondary": "#0080ff",

      "--xvia-header-icon-search-contrast": "#fff",

      "--color-link-header": "#0049A9",
      "--color-link-contrast-hover": "#0049A9",
      "--color-background-link-contrast-hover": "#400000",

      "--xvia-header-spacing-active-contrast": "#000000",
      "--color-background-header": "#000000",
      "--color-link-contrast-active": "#ffff00",

      "--color-button-inactive-text": "# ",
      "--color-button-inactive": "#400000",
      "--color-button-active": "#400000",

      "--color-link-top-bar": "#0080ff",
      "--color-button-background-top-bar": "#000",

      "--color-background-button-primary-outlined": "transparent",
      "--color-background-button-primary-outlined-border": "1px solid #400000",
      "--color-background-button-primary-outlined-hover": "#400000",
      "--color-button-text-primary-outlined": "#0080ff",

      "--color-text-contrast-90": "#0080ff",
      "--color-subtitle-1": "#fff",

      "--color-background-card-contrast": "#000",
      "--color-title-card-small-mark-contrast": "#40c090",
      "--color-title-card-contrast": "#0080ff",
      "--color-text-card-contrast": "#0080ff",
      "--color-text-side-filter-contrast": "#40c090",
      "--color-link-card-contrast": "#0080ff",
      "--color-text-service-card-contrast": "#fff",
      "--color-background-service-card-contrast": "#000",
      "--color-background-service-card-contrast-border": "2px solid #B0D2FD",

      "--color-background-button-primary-contrast": "transparent",
      "--color-background-switch-checked": "#00ffff",
      // === header detail ===
      "--color-gradient-primary": "#000000",
      // === breadcrumb ===
      "--color-breadcrumb": "#ffffff",
      "--color-breadcrumb-hover": "#ffffff",
      // === select input ===
      "--color-select-input-label": "#ffffff",
      "--color-select-input-options": "#0080ff",
      "--color-select-input-options-hover": "#0049a9",
      "--color-background-select-input-options-hover": "#400000",
      // === card accordion secretariats and bodies ===
      "--color-card-accordion-subtitle": "#ffffff",
      "--color-card-accordion-item": "#ffffff",
      "--color-card-accordion-arrow": "#ffffff",
      // === governor page ===
      "--color-card-governor": "#28272c",
      "--color-card-governor-text": "#ffffff",
      "--color-governor-text": "#ffffff",

      // === tab component ===
      "--color-background-tab-trigger": "#600040",
      "--color-text-tab-trigger": "#ffffff",
      "--color-text-tab-trigger-border-bottom": "#ffffff",

      // == dot component ===
      "--color-background-dot": "#600040",
      "--color-background-dot-active": "#fdfdfd",

      "--color-button-service-card-contrast": "#00ffff",
      "--color-link-service-card-contrast": "#0080ff",

      "--color-button-background-arrow": "#600040",
      "--color-button-border-arrow": "2px solid #fff",
      "--color-icon-arrow": "#fff",

      // === enum pagination ===
      "--color-icon-arrow-hover": "#0049a9",
      "--color-button-border-arrow-hover": "#ffffff",
      "--color-button-background-arrow-hover": "#600040",
      "--color-number-pagination": "#00ffff",
      "--color-number-pagination-hover": "#ffffff",
      "--color-background-number-pagination-hover": "#600040",
      "--color-number-pagination-active": "#ffffff",

      "--color-text-side-filter-active-contrast": "#40c090",
      "--color-background-base-video": "#000",

      "--color-button-background-fill-arrow": "#600040",

      // === card component ===
      "--color-background-card": "transparent",
      "--color-border-card": "2px solid #ffffff",

      // === calendar component ===
      "--color-arrow-picker": "#bcbcbc",
      "--color-arrow-picker-disabled": "#747474",
    },
  };

  const updateFontSizes = (delta: number) => {
    fontVariables.forEach((variable) => {
      const currentSize = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue(variable)
      );

      if (!isNaN(currentSize)) {
        let newSize = currentSize + delta;

        newSize = Math.max(MIN_FONT_SIZE, Math.min(MAX_FONT_SIZE, newSize));

        document.documentElement.style.setProperty(variable, `${newSize}px`);
      }
    });
  };

  const updateSpacingVariables = (delta: number) => {
    spacingVariables.forEach((variable) => {
      const currentSize = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue(variable)
      );

      if (!isNaN(currentSize)) {
        let newSize = currentSize + delta * SPACING_SCALE_FACTOR;
        newSize = Math.max(
          MIN_PADDING_SIZE,
          Math.min(MAX_PADDING_SIZE, newSize)
        );
        document.documentElement.style.setProperty(variable, `${newSize}px`);
      }
    });
  };

  const increaseFonts = () => {
    setAccessibility((prev) => {
      const newFontSize = Math.min(MAX_FONT_SIZE, prev.fontSize + 1);
      if (newFontSize !== prev.fontSize) {
        updateFontSizes(1);
        updateSpacingVariables(1);
      }
      return { ...prev, fontSize: newFontSize };
    });
  };

  const decreaseFonts = () => {
    setAccessibility((prev) => {
      const newFontSize = Math.max(MIN_FONT_SIZE, prev.fontSize - 1);
      if (newFontSize !== prev.fontSize) {
        updateFontSizes(-1);
        updateSpacingVariables(-1);
      }
      return { ...prev, fontSize: newFontSize };
    });
  };

  const applyContrastTheme = (contrast: "normal" | "high") => {
    const theme = colorThemes[contrast];

    Object.keys(theme).forEach((variable) => {
      document.documentElement.style.setProperty(
        variable,
        theme[variable as keyof typeof theme]
      );
    });
  };

  const toggleContrast = () => {
    setAccessibility((prev) => {
      const newContrast = prev.contrast === "normal" ? "high" : "normal";

      applyContrastTheme(newContrast);

      return { ...prev, contrast: newContrast };
    });
  };

  return (
    <AccessibilityContext.Provider
      value={{
        accessibility,
        setAccessibility,
        increaseFonts,
        decreaseFonts,
        toggleContrast,
      }}
    >
      {children}
    </AccessibilityContext.Provider>
  );
};

export const useAccessibility = () => useContext(AccessibilityContext);
