import React, { FC, MouseEventHandler, useMemo } from "react";
import classnames from "classnames";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "../Header/Avatar";
import { Menu, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LoadingContainer } from "./style";
import { Spin } from "antd";

export interface LoginButtonProps {
  onLogin: MouseEventHandler<HTMLButtonElement>;
  onLogout: MouseEventHandler<HTMLElement>;
  onAccountManagementRequest: MouseEventHandler<HTMLElement>;
  profile?: Record<string, unknown>;
  loading: boolean;
  widthFull?: boolean;
  stateDrawer?: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({
  onLogin,
  onLogout,
  onAccountManagementRequest,
  profile,
  loading,
  widthFull,
  stateDrawer,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(880));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const name = useMemo(() => {
    if (!profile?.name) {
      return;
    }
    const parts = (profile.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return profile.name;
  }, [profile?.name]);

  const content = (
    <div
      className={classnames({
        "xvia-header-btn": true,
        "xvia-header-btn__loading": !profile && loading,
        "xvia-header-btn__inactive": !!profile,
        "xvia-header-btn__width-Full": widthFull,
      })}
    >
      {loading ? (
        <LoadingContainer>
          <Spin />
        </LoadingContainer>
      ) : (
        <>
          {!profile ? (
            <button onClick={loading ? undefined : onLogin}>
              {!isSmallScreen && <PersonIcon />}
              Entrar
            </button>
          ) : (
            <>
              <div
                onClick={handleMenuOpen}
                className="xvia-header-user-avatar"
                title={String(name)}
              >
                <Avatar name={String(name)} isMediumScreen={isMediumScreen} />
              </div>
              <Menu
                sx={{
                  "& .MuiMenu-paper": {
                    width: stateDrawer ? "100%" : "auto",
                    boxShadow: stateDrawer
                      ? "none"
                      : "0px 4px 24px 0px #00000025",
                  },
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  key="user-account"
                  sx={{ color: "#000000DE" }}
                  onClick={(e) => {
                    onAccountManagementRequest(e);
                    handleMenuClose();
                  }}
                >
                  Painel do Cidadão
                </MenuItem>
                <MenuItem
                  key="logout"
                  sx={{ color: "#000000DE" }}
                  onClick={(e) => onLogout(e)}
                >
                  Sair
                </MenuItem>
              </Menu>
            </>
          )}
        </>
      )}
    </div>
  );

  return content;
};
