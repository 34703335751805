import axios from "axios";
import { UserinfoResponse } from "openid-client";

export interface UserInfo extends UserinfoResponse {
  resource_access: {
    [key: string]: {
      roles: string[];
    };
  };
  scope: string;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  dataNascimento: string;
  nomeMae: string;
  [key: string]: string | number | boolean | object | undefined;
}

interface UserProfile {
  userCpf: string;
  name: string;
  email: string;
  dataNascimento: string;
  nomeMae: string;
  externalData: any[];
  userInfo: UserInfo;
}

export async function getUserProfile(
  accessToken?: string
): Promise<UserProfile> {
  const perfilCidadaoUrl = process.env.SMARTPASS_PERFIL_CIDADAO_URL;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get<UserProfile>(
      `${perfilCidadaoUrl}/v1/profile`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}
