import { styled } from "@mui/material";

export const ContainerButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  "& div": {
    display: "flex",
  },

  "& button": {
    width: "30px",
    height: "30px",
    borderRadius: "8px",
    backgroundColor: "var(--color-primary-5)",
    border: "none",
    color: "var(--color-surface)",
    fontSize: "var(--font-subtitle-3)",
    fontWeight: "700",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    marginRight: "8px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      backgroundColor: "var(--color-primary)",
      color: "var(--color-grey-10)",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      transform: "scale(0.9)",
    },
  },
}));
