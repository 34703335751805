import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppResponse } from "shared-types";
import { Service } from "../util/Service";
import Cookies from "js-cookie";
import { isBrowser } from "../util/env-utils";

interface PortalEnv {
  BACKEND_ENDPOINT: string;
  SMARTPASS_LOGIN_URL: string;
  SMARTPASS_LOGIN_REALM: string;
  SMARTPASS_LOGIN_CLIENT_ID: string;
  SMARTPASS_LOGIN_ENABLE_PKCE: string;
  [key: string]: string;
}

interface PortalProviderProps {
  context: {
    serverData?: any;
    env?: PortalEnv;
    accessToken?: string;
    ssr: boolean;
  };
}

export type PortalMessageToApp = {
  detail?: {
    pushRoute?: string;
  };
};

export interface PortalContext {
  serverData?: any;
  env?: PortalEnv;
  accessToken?: string;
  backend?: Service;
  currentApp?: AppResponse;
  ssr: boolean;
  setAccessToken: (accessToken?: string) => void;
  setCurrentApp: (app?: AppResponse) => void;
  profile?: Record<string, unknown>;
  setProfile: (app: Record<string, unknown>) => void;
  noHeader?: boolean;
  sendMessageToApp?: (dataToApp: PortalMessageToApp) => void;
}

const CustomPortalContext = createContext<PortalContext>({
  setAccessToken: () => null,
  setCurrentApp: () => null,
  setProfile: () => null,
  ssr: false,
  env: {} as PortalEnv,
});

export const PortalProvider: FC<PortalProviderProps> = ({
  children,
  context,
}) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(
    context?.accessToken
  );

  const serverRenderedApp = useMemo(() => {
    const appFromGlobals = isBrowser ? window?.APP : undefined;
    return context?.serverData?.currentApp || appFromGlobals;
  }, [context?.serverData?.currentApp]);

  const serverRenderedProfile = useMemo(() => {
    const profileFromGlobals = isBrowser ? window?.PROFILE : undefined;
    return context?.serverData?.profile || profileFromGlobals;
  }, [context?.serverData?.profile]);

  const backendEndpoint = useMemo(() => {
    const backendEndpointFromGlobals = isBrowser
      ? window?.BACKEND_ENDPOINT
      : undefined;
    return context?.env?.BACKEND_ENDPOINT || backendEndpointFromGlobals;
  }, [context?.env?.BACKEND_ENDPOINT]);

  const noHeader = useMemo(() => {
    const noHeaderFromGlobals: boolean = isBrowser
      ? window?.__XVIA_NO_HEADER
      : false;
    const noHeaderFromCookie: boolean = Cookies.get("xviaNoHeader") === "true";
    return (
      context?.serverData?.noHeader ||
      noHeaderFromGlobals ||
      noHeaderFromCookie ||
      false
    );
  }, [context?.serverData?.noHeader]);

  const ssr = useMemo(() => {
    const ssrFromGlobal = isBrowser ? window?.SSR : false;
    return context?.ssr || ssrFromGlobal;
  }, [context?.ssr]);

  const [currentApp, setCurrentApp] = useState<AppResponse | undefined>(
    serverRenderedApp
  );
  const [profile, setProfile] = useState<Record<string, unknown>>(
    serverRenderedProfile
  );

  const backend = useRef<Service>(
    new Service({
      endpoint: backendEndpoint as string,
      accessToken,
    })
  );

  const sendMessageToApp = useCallback((dataToApp: PortalMessageToApp) => {
    const event = new CustomEvent("messageToApp", dataToApp);
    window.dispatchEvent(event);
  }, []);

  const contextData: PortalContext = {
    serverData: context?.serverData,
    env: context.env,
    backend: backend.current,
    ssr,
    accessToken,
    setAccessToken,
    setCurrentApp,
    currentApp,
    profile,
    setProfile,
    noHeader,
    sendMessageToApp,
  };

  const updateBasePath = useCallback(() => {
    if (isBrowser) {
      window.__APP_BASE__ = `/app/${currentApp?.slug}`;
    }
  }, [currentApp?.slug]);

  useEffect(() => {
    backend.current.setAccessToken(accessToken);
    updateBasePath();
  }, [accessToken, currentApp?.slug, updateBasePath]);

  updateBasePath();

  return (
    <CustomPortalContext.Provider value={contextData}>
      {children}
    </CustomPortalContext.Provider>
  );
};

export function usePortalProvider(): PortalContext {
  return useContext(CustomPortalContext);
}
