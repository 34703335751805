import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import {
  LoginButton,
  LoginButtonProps,
} from "../components/LoginButton/LoginButton";
import { SearchBar } from "../components/Header/SearchBar/SearchBar";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { usePortalAuth } from "../providers/keycloak-provider";
import { usePortalProvider } from "../providers/portal-provider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import {
  HeaderActionsContent,
  HeaderActionsDialog,
  HeaderActionsDrawer,
  HeaderActionsDrawerClose,
  HeaderActionsDrawerWrap,
  HeaderActionsIconButton,
  HeaderActionsWrap,
  MenuContainer,
  MenuItem,
  MenuItemSmall,
  MenuSmallMarker,
} from "../components/Header/style";
import MenuIcon from "@mui/icons-material/Menu";
import { getUserProfile } from "shared-hooks";

export const HeaderActionsContainer: FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(880));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down(430));
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const { login, logout } = usePortalAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const { ssr, profile, setProfile, sendMessageToApp } = usePortalProvider();
  const history = useHistory();
  const location = useLocation();

  const menuItems = [
    { label: "Início", path: "/" },
    { label: "Serviços", path: "/carta-de-servicos" },
    { label: "Notícias", path: "/noticias" },
    { label: "Governo", path: "/secretarias-e-orgaos" },
  ];

  const SimpleDialog = ({ open }: { open: boolean }) => {
    return (
      <HeaderActionsDialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          <HeaderActionsIconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            disableRipple
            className="dialog"
          >
            <CloseIcon />
          </HeaderActionsIconButton>
        </DialogTitle>
        <DialogContent sx={{ marginTop: "20px" }}>
          <SearchBar
            className="xvia-home-search__input"
            placeholder="O que você procura?"
            onClickSearch={onClickSearch}
            aria-label="Campo de busca de serviços"
          />
        </DialogContent>
      </HeaderActionsDialog>
    );
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };

  const handleCatalogClick = (menuItemPath: string, mouseEvent: MouseEvent) => {
    if (location.pathname.includes("/app/catalog")) {
      mouseEvent.preventDefault();
      sendMessageToApp?.({
        detail: {
          pushRoute: menuItemPath,
        },
      });
      setCurrentPath(menuItemPath);
    }
  };

  useEffect(() => {
    const loadUserInfo = async () => {
      setLoading(true);
      if (!initialized) return;

      setLoading(false);

      if (keycloak?.authenticated) {
        setLoading(true);

        try {
          const { userInfo, ...profile } = await getUserProfile(
            keycloak?.token
          );

          setProfile({ ...userInfo, ...profile });
        } catch (e) {
          const userInfoKeycloak = await keycloak?.loadUserInfo();

          setProfile(userInfoKeycloak);
        } finally {
          setLoading(false);
        }
      }
    };

    loadUserInfo();
  }, [keycloak, initialized, setProfile]);

  const onLogin = useCallback(() => {
    if (ssr) {
      login();
      return;
    }
    keycloak?.login();
  }, [login, keycloak, ssr]);

  const onLogout = useCallback(() => {
    if (ssr) {
      logout();
      return;
    }
    keycloak?.logout();
  }, [logout, keycloak, ssr]);

  const onAccountManagementRequest = () => {
    history.push("/app/painel-cidadao");
  };

  const onClickSearch = useCallback(
    (value: string) => {
      setOpen(false);
      setState(false);

      if (
        sendMessageToApp &&
        (location.pathname.includes("/app/catalog") ||
          location.pathname === "" ||
          location.pathname === "/")
      ) {
        sendMessageToApp({
          detail: {
            pushRoute: `/search/${value}`,
          },
        });
      } else if (window) {
        window.location.href = `/app/catalog/search/${value}`;
      }
    },
    [location, sendMessageToApp]
  );

  const props: LoginButtonProps = {
    onLogin,
    onLogout,
    onAccountManagementRequest,
    profile,
    loading,
  };

  const [currentPath, setCurrentPath] = useState<string>("/");

  // eslint-disable-next-line
  const checkCurrentPath = useCallback(
    (itemPath: string) => {
      if (location.pathname.includes("/app/catalog")) {
        return (
          currentPath === itemPath || (currentPath === "" && itemPath === "/")
        );
      } else {
        return (
          location.pathname === `/app/catalog${itemPath}` ||
          (location.pathname === "/app/catalog" && itemPath === "/")
        );
      }
    },
    [currentPath, location.pathname]
  );

  return (
    <HeaderActionsWrap isSmallScreen={isSmallScreen}>
      {!isSmallScreen && (
        <HeaderActionsContent>
          <MenuContainer isSmallScreen={isSmallScreen}>
            {menuItems.map((item) => (
              <MenuItem
                key={item.path}
                href={`/app/catalog${item.path}`}
                onClick={(e: MouseEvent) => handleCatalogClick(item.path, e)}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuContainer>
          <HeaderActionsIconButton
            onClick={() => setOpen(true)}
            aria-label="Pesquiser no portal"
            disableRipple
            className="search"
          >
            <SearchIcon />
          </HeaderActionsIconButton>
        </HeaderActionsContent>
      )}
      <SimpleDialog open={open} />
      {!isExtraSmallScreen && <LoginButton {...props} />}
      {isSmallScreen && (
        <>
          <HeaderActionsIconButton
            onClick={() => setState(true)}
            aria-label="menu"
            className="menu"
          >
            <MenuIcon />
          </HeaderActionsIconButton>
          <HeaderActionsDrawer
            anchor="right"
            open={state}
            onClose={toggleDrawer(false)}
          >
            <HeaderActionsDrawerWrap>
              <HeaderActionsDrawerClose onClick={() => setState(false)}>
                <CloseIcon />
              </HeaderActionsDrawerClose>
              <div>
                <MenuContainer isSmallScreen={isSmallScreen}>
                  {menuItems.map((item) => (
                    <React.Fragment key={item.path}>
                      <MenuItemSmall
                        href={`/app/catalog${item.path}`}
                        onClick={(e: MouseEvent) =>
                          handleCatalogClick(item.path, e)
                        }
                      >
                        {item.label}
                      </MenuItemSmall>
                      <MenuSmallMarker />
                    </React.Fragment>
                  ))}
                </MenuContainer>
              </div>
              <SearchBar
                className="xvia-home-search__input"
                placeholder="O que você procura?"
                onClickSearch={onClickSearch}
                aria-label="Campo de busca de serviços"
              />
              <LoginButton widthFull stateDrawer={state} {...props} />
            </HeaderActionsDrawerWrap>
          </HeaderActionsDrawer>
        </>
      )}
    </HeaderActionsWrap>
  );
};
