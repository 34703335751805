import { FC, useCallback, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { getOriginFromUrl } from "../util/url";
import { AppResponse } from "shared-types";
import { usePortalAuth } from "../providers/keycloak-provider";
import { usePortalProvider } from "../providers/portal-provider";
import { useHistory } from "react-router-dom";
import { isBrowser } from "../util/env-utils";
import { getUserProfile, UserInfo } from "shared-hooks";

interface AppMessage {
  type: string;
  payload: any;
}

function isValidMessage(
  event: MessageEvent<any>,
  currentApp?: AppResponse
): boolean {
  return (
    event.origin === window?.location?.origin ||
    currentApp?.url?.indexOf(event.origin) !== -1
  );
}

function getTargetWindow(currentApp: AppResponse): Window | null {
  const isIframe = currentApp?.integrationType === "iframe";

  if (isIframe) {
    const iframe = document?.getElementById(currentApp?.slug);
    if (!iframe) {
      return null;
    }
    return (iframe as HTMLIFrameElement).contentWindow;
  }

  return window;
}

function sendMessageToApp(message: AppMessage, currentApp?: AppResponse): void {
  if (!currentApp) {
    // App not loaded within the valid timeout
    // nothing to do here
    return;
  }

  const isIframe = currentApp.integrationType === "iframe";

  const allowedOrigin = isIframe
    ? getOriginFromUrl(currentApp?.url)
    : window?.location.origin;

  if (!allowedOrigin || !allowedOrigin.length) {
    return;
  }
  let target = getTargetWindow(currentApp);

  if (target === null && currentApp.integrationType === "iframe") {
    // Maybe the iframe is not ready yet. in that case we try again after a few seconds
    const retryDelay = 2000;
    setTimeout(() => {
      target = getTargetWindow(currentApp as AppResponse);
      target?.postMessage(message, allowedOrigin);
    }, retryDelay);
    return;
  }

  target?.postMessage(message, allowedOrigin);
}

export const AppMessageListener: FC = () => {
  const { currentApp } = usePortalProvider();
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const { accessToken } = usePortalAuth();
  const history = useHistory();

  const messageListener = useCallback(
    (event: MessageEvent<any>) => {
      if (!isValidMessage(event, currentApp)) {
        return;
      }

      let data: any = event.data;

      if (Object.prototype.toString.apply(data) === "[object String]") {
        try {
          data = JSON.parse(data);
        } catch (e) {
          // do nothing
        }
      }

      // Do we trust the sender of this message?  (might be
      // different from what we originally opened, for example).
      switch (data?.type) {
        case "LOGIN":
          keycloak?.login(data.payload);
          break;

        case "LOGOUT":
          keycloak?.logout(data.payload);
          break;

        case "REGISTER":
          keycloak?.register(data.payload);
          break;

        case "ACCOUNT_MANAGEMENT":
          keycloak?.accountManagement();
          break;

        case "REDIRECT_TO":
          document.location.href = data.payload;
          break;

        case "ACCESS_TOKEN":
          sendMessageToApp(
            {
              type: "ACCESS_TOKEN_RESPONSE",
              payload: keycloak?.token || getAccessTokenFromCookie(),
            },
            currentApp
          );
          break;

        case "USER_INFO":
          let payloadUserInfo: UserInfo;

          getUserProfile(keycloak?.token)
            .then(({ userInfo, ...profile }) => {
              payloadUserInfo = { ...userInfo, ...profile };
            })
            .catch(async () => {
              try {
                const userInfoKeycloak = await keycloak?.loadUserInfo();

                payloadUserInfo = userInfoKeycloak as UserInfo;
              } catch (e) {
                // do nothing
              }
            })
            .finally(() => {
              sendMessageToApp(
                {
                  type: "USER_INFO_RESPONSE",
                  payload: payloadUserInfo,
                },
                currentApp
              );
            });
          break;

        case "GET_CURRENT_LOCATION":
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              sendMessageToApp(
                {
                  type: "GET_CURRENT_LOCATION_RESPONSE",
                  payload: pos.coords,
                },
                currentApp
              );
            },
            (err) => {
              console.error("Error getting current location", err);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );
          break;

        case "DOWNLOAD":
          //Run in webviwer
          if (isRunningInWebview()) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "DOWNLOAD",
                payload: data.payload,
              })
            );
          } else {
            window.open(data.payload.url);
          }
          break;
      }
    },
    [keycloak, currentApp]
  );

  useEffect(() => {
    window?.addEventListener("message", messageListener, false);

    return () => {
      window?.removeEventListener("message", messageListener);
    };
  }, [messageListener]);

  useEffect(() => {
    const cancelHistoryListener = history.listen((location) => {
      sendMessageToApp({
        type: "LOCATION_CHANGED",
        payload: location.pathname,
      });
    });
    return () => {
      cancelHistoryListener();
    };
  }, [history]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    sendMessageToApp(
      {
        type: "INITIALIZED",
        payload: true,
      },
      currentApp
    );

    if (initialized && !keycloak?.authenticated) {
      sendMessageToApp(
        {
          type: "NOT_AUTHENTICATED",
          payload: undefined,
        },
        currentApp
      );
      return;
    }

    sendMessageToApp(
      {
        type: "NEW_TOKEN",
        payload: accessToken,
      },
      currentApp
    );
  }, [accessToken, currentApp, initialized, keycloak?.authenticated]);

  return null;
};

function getAccessTokenFromCookie(): string | undefined {
  try {
    const key = "kcToken=";
    return window.atob(
      document.cookie.slice(
        document.cookie.indexOf(key) + key.length,
        document.cookie.indexOf(";")
      )
    );
  } catch (e) {
    return undefined;
  }
}
function isRunningInWebview(): boolean {
  return isBrowser ? !!window?.ReactNativeWebView : false;
}
