import { Avatar as MUIAvatar } from "@mui/material";
import Stack from "@mui/material/Stack";

interface AvatarProps {
  name: string;
  isMediumScreen?: boolean;
}

function stringAvatar(name: string, isMediumScreen: boolean | undefined) {
  return {
    sx: {
      width: isMediumScreen ? "100%" : "auto",
      minWidth: "40px",
      backgroundColor: "#0049A9",
      color: "#FDFDFD",
      transition: "all 0.5s ease-in-out",
      "&:hover": {
        backgroundColor: "#0A2F66",
      },
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export const Avatar = ({ name, isMediumScreen }: AvatarProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <MUIAvatar variant="rounded" {...stringAvatar(name, isMediumScreen)} />
    </Stack>
  );
};
