import { useAccessibility } from "../../providers/accessibility-hooks";
import { ContainerButtons } from "./style";
import ContrastIcon from "@mui/icons-material/Contrast";
export const AccessibilityButtons = () => {
  const { increaseFonts, decreaseFonts, toggleContrast } = useAccessibility();

  return (
    <ContainerButtons id="accessibility-buttons">
      <div>
        <button onClick={decreaseFonts}>A-</button>
        <button onClick={increaseFonts}>A+</button>
        <button onClick={toggleContrast}>
          <ContrastIcon />
        </button>
      </div>
    </ContainerButtons>
  );
};
