import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/less/main.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { KeycloakProvider } from "./providers/keycloak-provider";
import { PortalContext, PortalProvider } from "./providers/portal-provider";
import { BrowserRouter } from "react-router-dom";
import { AccessibilityContextProvider } from "./providers/accessibility-hooks";

let context: Partial<PortalContext> = {};
if (process.env.NODE_ENV !== "production") {
  context = {
    env: {
      BACKEND_ENDPOINT: BACKEND_ENDPOINT,
      SMARTPASS_LOGIN_URL: SMARTPASS_LOGIN_URL,
      SMARTPASS_LOGIN_REALM: SMARTPASS_LOGIN_REALM,
      SMARTPASS_LOGIN_CLIENT_ID: SMARTPASS_LOGIN_CLIENT_ID,
      SMARTPASS_LOGIN_ENABLE_PKCE: SMARTPASS_LOGIN_ENABLE_PKCE,
    },
  };
  window.__XVIA_PORTAL_LOADED__ = true;
}

ReactDOM.hydrate(
  <React.StrictMode>
    <PortalProvider context={context as PortalContext}>
      <KeycloakProvider>
        <BrowserRouter basename={"/"}>
          <AccessibilityContextProvider>
            <App />
          </AccessibilityContextProvider>
        </BrowserRouter>
      </KeycloakProvider>
    </PortalProvider>
  </React.StrictMode>,
  document.getElementById("xvia-main")
);

// If you want to start measuring performance in your app, pass a function
// to log results here (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
