import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ExternalLink } from "../Link/ExternalLink";
import { AppBarStyled, BoxLink, Marker, Wrap } from "./style";
import { AccessibilityButtons } from "../AccessibilityButtons/AccessibilityButtons";

export const TopBar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(530));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down(430));

  const menuItems = [
    {
      label: "Diário Oficial",
      path: "https://diariooficial.cepe.com.br/diariooficialweb/#/home?diario=MQ%3D%3D",
    },
    {
      label: "Transparência",
      path: "https://transparencia.pe.gov.br/",
    },
    {
      label: "Ouvidoria",
      path: "https://portal.pe.xvia.com.br/app/ouvidoria",
    },
    {
      label: "Licitações",
      path: "http://www.licitacoes.pe.gov.br/web/ListaLicitacao.aspx",
    },
  ];

  return (
    <AppBarStyled position="static" className="app-bar-container">
      <Wrap className="xvia-container">
        <Box
          sx={{
            display: "flex",
            flex: 1,
            gap: isSmallScreen ? "8px" : "24px",
            justifyContent: isSmallScreen ? "flex-start" : "end",
            alignItems: "center",
            transition: "all 0.5s ease-in-out 0s",
            overflowX: "auto",
            whiteSpace: "nowrap",
            paddingLeft: isSmallScreen ? "8px" : "0",
            paddingRight: isSmallScreen ? "8px" : "0",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {menuItems.map((item, index) => (
            <React.Fragment key={item.path}>
              <ExternalLink link={item.path}>
                <BoxLink
                  key={item.label}
                  isExtraSmallScreen={isExtraSmallScreen}
                >
                  {item.label}
                </BoxLink>
              </ExternalLink>
              {index < menuItems.length - 1 && <Marker />}
            </React.Fragment>
          ))}
          <AccessibilityButtons />
        </Box>
      </Wrap>
    </AppBarStyled>
  );
};
