import React, { useEffect } from "react";

import { Route, Switch, useLocation } from "react-router-dom";
import { AppLoaderContainer } from "./containers/AppLoaderContainer";
import { NotFoundContainer } from "./containers/NotFoundContainer";
import { LoginContainer } from "./containers/LoginContainer";
import ReactGA from "react-ga4";
import { usePageTrackingGA } from "./hooks";
import { AppMessageListener } from "./containers/AppMessageListener";
import { isBrowser } from "./util/env-utils";

const App = () => {
  // GA Tracking
  const location = useLocation();

  usePageTrackingGA(location);

  useEffect(() => {
    const googleAnalyticsId = isBrowser
      ? window?.GOOGLE_ANALYTICS_ID
      : undefined;
    if (!googleAnalyticsId) return;
    ReactGA.initialize(googleAnalyticsId);
  }, []);

  return (
    <LoginContainer>
      <AppMessageListener />
      <Switch>
        <Route path={"/app"}>
          <AppLoaderContainer />
        </Route>
        <Route exact={true} path="/">
          <AppLoaderContainer slug={"catalog"} />
        </Route>
        <Route path="*">
          <NotFoundContainer />
        </Route>
      </Switch>
    </LoginContainer>
  );
};

export default App;
