import React, { FC } from "react";
import { TopBar } from "./TopBar";

export interface HeaderProps {
  rightElement: React.ReactElement;
}

export const Header: FC<HeaderProps> = (props) => (
  <header className="xvia-header-area">
    <TopBar />
    <div className="xvia-header-spacing xvia-container">
      <div className="xvia-header-logo">
        <a href="/">
          <img
            src="/assets/img/logo/logo-pe.png"
            alt="Logo de Pernambuco"
            width={250}
          />
        </a>
      </div>
      <div className="xvia-header-elements">{props.rightElement}</div>
    </div>
  </header>
);
