import {
  AppBar,
  Box,
  BoxProps,
  Link,
  styled,
  IconButton,
  Dialog,
  Drawer,
} from "@mui/material";

interface BoxLinkProps extends BoxProps {
  isExtraSmallScreen: boolean;
}

interface MenuContainerPros extends BoxProps {
  isSmallScreen: boolean;
}

interface HeaderActionsWrapProps {
  isSmallScreen: boolean;
}

export const MenuContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSmallScreen",
})<MenuContainerPros>(({ isSmallScreen }) => ({
  display: "flex",
  flexDirection: isSmallScreen ? "column" : "row",
  gap: isSmallScreen ? "1rem" : "3rem",
  alignItems: isSmallScreen ? "start" : "center",
}));

export const BoxLink = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isExtraSmallScreen",
})<BoxLinkProps>(({ isExtraSmallScreen }) => ({
  display: "flex",
  alignItems: "center",
  color: "var(--color-link-top-bar)",
  fontSize: isExtraSmallScreen ? "var(--font-body-3)" : "var(--font-body-1)",
  fontWeight: 500,
  opacity: 0.75,
  transition: "opacity 0.5s ease-in-out",

  "&:hover": {
    opacity: 1,
    backgroundColor: "var(--color-background-link-contrast-hover)",
  },
}));

export const AppBarStyled = styled(AppBar)(() => ({
  height: 45,
  backgroundColor: "var(--color-button-background-top-bar)",
  boxShadow: "none",
}));

export const Wrap = styled("div")(() => ({
  display: "flex",
  justifyContent: "end",
  height: 45,
  padding: "0px",
}));

export const Marker = styled("div")(() => ({
  marginX: 0,
  width: "1px",
  height: "12px",
  backgroundColor: "#FDFDFD",
}));

export const MenuItem = styled("a")(() => ({
  textDecoration: "none",
  fontSize: "var(--font-body-1)",
  fontWeight: 500,
  color: "var(--color-link-header)",
  position: "relative",
  transition: "color 0.5s ease-in-out",
  "&:hover": {
    color: "var(--color-link-contrast-hover)",
    backgroundColor: "var(--color-background-link-contrast-hover)",
  },
  "&.active": {
    color: "var(--color-link-contrast-active)",
    "&::after": {
      content: '""',
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: "-5px",
      width: "6px",
      height: "6px",
      backgroundColor: "#8EC63F",
      borderRadius: "50%",
    },
  },
}));

export const MenuItemSmall = styled(Link)(() => ({
  textDecoration: "none",
  fontSize: "var(--font-body-1)",
  fontWeight: 500,
  color: "#28272C",
  position: "relative",
  transition: "color 0.5s ease-in-out",
  "&:hover": {
    color: "#0049A9",
  },
}));

export const MenuSmallMarker = styled("div")(() => ({
  width: "100%",
  height: "1px",
  backgroundColor: "#999EB0",
}));

export const HeaderActionsWrap = styled("div")<HeaderActionsWrapProps>(
  ({ isSmallScreen }) => ({
    display: "flex",
    gap: isSmallScreen ? "1rem" : "3rem",
    alignItems: "center",
    color: "#28272C",
  })
);

export const HeaderActionsContent = styled("div")(() => ({
  display: "flex",
  gap: "3rem",
  alignItems: "center",
}));

export const HeaderActionsIconButton = styled(IconButton)(() => ({
  "&.search": {
    color: "var(--color-link-contrast)",
    transition: "color 0.5s ease-in-out",

    "&:hover": {
      color: "var(--xvia-header-icon-search-contrast)",
    },
  },
  "& button": {
    color: "var(--xvia-header-icon-search-contrast)",
  },

  "&.menu": {
    color: "#0049A9",
  },

  "&.dialog": {
    position: "absolute",
    right: 40,
    top: -10,
    color: "#FDFDFD",
  },
}));

export const HeaderActionsDialog = styled(Dialog)(() => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(8px)",
  },
  "& .MuiPaper-root": {
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    position: "absolute",
    top: 150,
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: 0,
  },
}));

export const HeaderActionsDrawer = styled(Drawer)(() => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "#D6D7E0",
    width: "100%",
    padding: "64px 16px",
  },
}));

export const HeaderActionsDrawerWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  color: "#28272C",
}));

export const HeaderActionsDrawerClose = styled("div")(() => ({
  width: "100%",
  textAlign: "end",
  cursor: "pointer",
}));
