import { SSRKeycloakProvider } from "@react-keycloak/ssr";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from "react";
import { AuthClientTokens } from "@react-keycloak/core";
import { usePortalProvider } from "./portal-provider";
import { WebCookiePersistor } from "../util/web-cookie-persistor";
import { isBrowser } from "../util/env-utils";
const persistor = new WebCookiePersistor();

interface PortalAuthContext {
  accessToken?: string;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<PortalAuthContext>({
  login: () => null,
  logout: () => null,
});

export const KeycloakProvider: FC = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const { env, ssr } = usePortalProvider();

  const updateToken = useCallback((data: AuthClientTokens) => {
    setAccessToken(data.token);
  }, []);

  const login = useCallback(() => {
    if (!ssr) {
      return;
    }

    document.location.href = `/login?redirectUrl=${encodeURIComponent(
      window.location.href
    )}`;
  }, [ssr]);

  const logout = useCallback(() => {
    if (!ssr) {
      return;
    }
    document.location.href = `/logout?redirectUrl=${encodeURIComponent(
      window.location.origin
    )}`;
  }, [ssr]);

  const globalLoginUrl = isBrowser ? window?.SMARTPASS_LOGIN_URL : undefined;

  const globalLoginRealm = isBrowser ? window?.SMARTPASS_LOGIN_REALM : "";

  const globalLoginClientId = isBrowser
    ? window?.SMARTPASS_LOGIN_CLIENT_ID
    : "";

  const isPkceEnabled = (): boolean => {
    if (!isBrowser) return false;

    return (
      env?.SMARTPASS_LOGIN_ENABLE_PKCE === "true" ||
      window?.SMARTPASS_LOGIN_ENABLE_PKCE === "true"
    );
  };

  return (
    <SSRKeycloakProvider
      keycloakConfig={{
        url: env?.SMARTPASS_LOGIN_URL || globalLoginUrl,
        realm: env?.SMARTPASS_LOGIN_REALM || globalLoginRealm,
        clientId: env?.SMARTPASS_LOGIN_CLIENT_ID || globalLoginClientId,
      }}
      persistor={persistor}
      onTokens={updateToken}
      initOptions={{
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        pkceMethod: isPkceEnabled() ? "S256" : false,
        enableLogging: true,
      }}
    >
      <AuthContext.Provider value={{ accessToken, login, logout }}>
        {children}
      </AuthContext.Provider>
    </SSRKeycloakProvider>
  );
};

export function usePortalAuth(): PortalAuthContext {
  return useContext(AuthContext);
}
